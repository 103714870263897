body {
  background-color: #212529;
}

a {
  color: #51C4D3;
}

.App {
  background-color: #132C33;
  color: #D8E3E7;
}

.prim-text {
  color: #D8E3E7;
}

.sec-text {
  color: #51C4D3;
}

.tert-text {
  color: #126E82;
}

.prim-bg {
  background-color: #132C33;
}

.sec-bg {
  background-color: #126E82;
}

.tert-bg {
  background-color: #51C4D3;
}

.logo {
  margin-right: 16px;
  border-radius: 8px;
}

.jumbo-section {
  margin: 15px;
  padding: 30px;
  border-radius: 15px;
}

.section {
  margin: 15px;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 5px 5px 0px #51C4D3;
}

.portrait {
  border-radius: 50%;
  border-style: solid;
  border-color: #51C4D3;
}

.work-carousel {
  max-width: 75%;
  max-height: auto;
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 20px;
}

.carousel-img {
  border-radius: 15px;
}

.footer-info {
  padding-top: 15px;
}